import i18next from "i18next";

export const functionChangeLanguge = (
    lngCode: string,
    // bussinessName: string
  ) => {
    i18next.changeLanguage(lngCode, (error: any) => {
      // //console.log(error);
    });
    // var localData = getShopDataFromStrg("/" + bussinessName);
    // if (localData !== null) {
    //   localData.savedLanguage = lngCode;
    //   localStorage.setItem("shop/" + bussinessName, JSON.stringify(localData));
    // }
  
    document
      .getElementsByTagName("html")[0]
      .setAttribute("dir", lngCode === "ar" ? "rtl" : "ltr");
    document
      .getElementsByTagName("body")[0]
      .setAttribute("dir", lngCode === "ar" ? "rtl" : "ltr");
  };