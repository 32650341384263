import React from "react";
import Loader from "../Loader";
interface ButtonProps {
  titleText: string;
  onPressHandler: () => void;
  loading?: boolean;
}
const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { titleText, onPressHandler, loading } = props;
  return (
    <div
      className="button_common"
      style={{ marginTop: 30 }}
      onClick={!loading ? onPressHandler : () => {}}
    >
      {!loading ? (
        <span>{titleText}</span>
      ) : (
        <Loader
          height={15}
          width={15}
          colorProp={"white"}
          wrapperheight="1vh"
        />
      )}
    </div>
  );
};

export default Button;
