import React, { useEffect, useState } from "react";
import "./homeStyles.css";
import { functionChangeLanguge } from "../shared/utils";
import useTokenByBussiness from "../../hooks/useTokenByOutlet";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import useSendOtp from "../../hooks/useSendOtp";
import ValidateOtp from "../components/VaidateOtp/ValidateOtp";
import { ToastContainer } from "react-toastify";
import { useScrollLock } from "../../hooks/useScrollLock";
import "react-phone-input-2/lib/style.css";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import usePlaceOrder from "../../hooks/usePlaceOrder";
import Button from "../components/Button";
import axios from "axios";
import {
  ALL_COUNTRIES_ARRAY,
  CREDIT_CARD_MODE,
  ICUSTOMERINFO,
  ISPRODUCTION,
  getCustomerData,
} from "./utils";
import CreateCustomer from "../components/CreateCustomer/CreateCustomer";
const HomePage: React.FC = () => {
  const { bussinessName, paymentLink } = useParams();
  const { sendOtpHandler, otpData, errorMessage, otpLoading } = useSendOtp();
  var getData = getCustomerData();
  const [customerData, setCustomerData] = useState<ICUSTOMERINFO>(
    getData !== null ? getData : ({} as ICUSTOMERINFO)
  );
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const { lockScroll, unlockScroll } = useScrollLock();
  const [showValidateOtp, setShowValidateOtp] = useState<boolean>(false);
  const { buttonLoading, placeOrderEndpoint } = usePlaceOrder();
  const [phoneNumberLenght, setPhoneNumberLength] = useState<number>(0);
  const [countryCode, setCountryCode] = useState<string>("");
  const [userIp, setUserIp] = useState<string>("");
  const [userIpProxy, setUserProxyIp] = useState<any>({});
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  //@ts-ignore
  const userWifiIp = window.userWifiIp;
  const contryCodeLocal = "iq";
  const [showError, setShowError] = useState<boolean>(false);
  const {
    getUserToken,
    loading,
    notFound,
    userToken,
    paymentLinkInfo,
    errorMessageBussines,
    bussinessInfo,
  } = useTokenByBussiness();
  const { t } = useTranslation();
  var currentUrl = window.location.href;
  const onChangePhoneHandler = (phone: string, data: CountryData) => {
    const findIndex = ALL_COUNTRIES_ARRAY.findIndex(
      (item) => item.phone == data.dialCode
    );
    var startsWithZero = phone.startsWith(countryCode + "0");
    var validatePhone = phone;
    if (startsWithZero) {
      var validatePhone = phone.replace(
        new RegExp(countryCode + "0", "i"),
        countryCode
      );
    }

    var user_phone_length = validatePhone.length - data.dialCode.length;
    console.log(phone);
    console.log(
      "validatePhone :",
      validatePhone,
      "dialCode :",
      data.dialCode.length
    );
    console.log(
      "All countries",
      ALL_COUNTRIES_ARRAY[findIndex].phoneLength,
      "userPhone Phone",
      user_phone_length
    );
    if (ALL_COUNTRIES_ARRAY[findIndex].phoneLength === user_phone_length) {
      setPhoneNumberLength(ALL_COUNTRIES_ARRAY[findIndex].phoneLength!);
      setCountryCode(data.dialCode);
      setPhoneNumber(phone);
      setShowError(false);
    } else {
      setPhoneNumber(phone);
      setShowError(true);
    }
  };
  const getCurrentDate = () => {
    var currentDate = new Date();
    //  var formatedDate = moment(currentDate).format('August 01, 2023')
    var formatedDate = moment(currentDate).format("MMMM DD, YYYY");
    return formatedDate;
  };
  const showValidateModal = () => {
    setShowValidateOtp(true);
    lockScroll();
  };
  const openCreateNameModalHandler = () => {
    lockScroll();
    setShowCreateModal(true);
  };
  const closeCreateNamePop = () => {
    var getData = getCustomerData();
    if (getData !== null) {
      setCustomerData(getData);
    }
    setShowCreateModal(false);
    unlockScroll();
  };
  const closeValidateModal = () => {
    var getData = getCustomerData();
    if (getData !== null) {
      setCustomerData(getData);
    }
    setShowValidateOtp(false);
    unlockScroll();
  };

  const sendOtpFunction = () => {
    // console.log(userToken);
    var validatePhone = phoneNumber.replace(
      new RegExp(countryCode + "0", "i"),
      countryCode
    );
    var userPhoneLength = validatePhone.length - countryCode.length;

    if (validatePhone.length === 0 || userPhoneLength !== phoneNumberLenght) {
      setShowError(true);
      return;
    }
    if (bussinessInfo) {
      sendOtpHandler(
        validatePhone,
        countryCode,
        bussinessInfo.business_id.toString(),
        bussinessInfo.name,
        "en",
        userToken
      );
    }
    // showValidateModal();
  };
  const navigateToPayment = () => {
    if (Object.keys(customerData).length > 0 && customerData.name !== "New") {
      // window.open(bussinessInfo?.billing_gateways[0]., "_self");
      if (paymentLinkInfo && bussinessInfo) {
        var orderValues = {
          billingGateway: bussinessInfo.billing_gateways[0].value,
          area: "",
          cart: "[]",
          total_price: paymentLinkInfo.amount,
          mobile_number: phoneNumber,
          customer_name: customerData.name,
          customer_id: customerData.id.toString(),
          order_instructions: "",
          delivery_fee: "0",
          order_link: currentUrl,
          time: "",
          payment_mode: "card",
          userIpInfo: userIpProxy,
          order_type: "paymentlink",
          campaign_date: new Date().getTime().toString(),
          status: "Initial",
          outlet_id: paymentLinkInfo.outlet_id,
          recipient_id: "",
          exchange_rate: "1",
          campaign_type: "Direct",
          isDev: 0, // PROD: 0, REHAN_ORDER_TEST: 2
          greeting_message: "",
          isCrDev: 0, // should be 0 in prod and 1 in dev,
          payment_link_code: paymentLink!,
        };

        placeOrderEndpoint(orderValues, userToken).then((redirectionLink) => {
          if (redirectionLink) {
            window.location.replace(redirectionLink);
          }
        });
      }
    } else {
      openCreateNameModalHandler();
    }
  };

  const getValues = async () => {
    if (userWifiIp !== "{userWifiIp}") {
      const resMeemApi = await axios.get(
        `https://orderapi.dukany.io/api/rjs/proxyIpCall?ip=${userWifiIp}`
      );
      setCountryCode(
        resMeemApi.data?.countryCode ? resMeemApi.data?.countryCode : "iq"
      );
      setUserProxyIp(resMeemApi.data);
      localStorage.setItem("userIpInfo", JSON.stringify(resMeemApi.data));
      setUserIp(userWifiIp);
    } else {
      setUserIp("0.0.0.0");
    }
    /*new line we are making requests on server side*/
  };
  const RenderRow = ({ title, value }: any) => {
    return (
      <div>
        <div className="txt_info_title margin_info_field">{title}</div>
        <div className="txt_info_title txt_info_descp">{value}</div>
      </div>
    );
  };
  useEffect(() => {
    getValues();
    getUserToken(bussinessName!, paymentLink!);
    functionChangeLanguge("en");
    console.log("CREDIT CARD MODE ", CREDIT_CARD_MODE);
    console.log(ISPRODUCTION);
    console.log(currentUrl);
  }, []);
  useEffect(() => {
    if (bussinessInfo) {
      const favicon = document.getElementById("favicon");
      //@ts-ignore
      favicon.href = bussinessInfo.site_logo;
    }
  }, [bussinessInfo]);
  useEffect(() => {
    if (otpData !== undefined) {
      showValidateModal();
    }
  }, [otpData]);
  return (
    <>
      {!loading ? (
        <div className="landscape">
          {!notFound ? (
            <>
              <ToastContainer />
              <div className="disp_flex justify_center bussines_name">
                <img
                  src={bussinessInfo?.site_logo}
                  className="bussiness_img_style"
                />
                <div style={{ marginLeft: 10 }}>{bussinessInfo?.name}</div>
              </div>
              <div className="bussinessCardInfo ">
                <div className="disp_flex justify_space_between payLinkWrapper">
                  <div className="payLinkId">#{paymentLink}</div>

                  <div className="payLinkId">{getCurrentDate()}</div>
                  {/* <div className="payLinkId">August 01, 2023</div> */}
                </div>
                <div className="disp_flex_column orderInfoWrapper">
                  {Object.keys(customerData).length > 0 && (
                    <h2 className="name_text--h2">
                      Hello, {customerData.name}!
                    </h2>
                  )}
                  <p className="text-subtitle slot_availble_color">
                    Only {paymentLinkInfo?.left_number_of_usage} slots available
                  </p>
                  <div>
                    <span className="total_price">
                      {bussinessInfo?.currency.en}{" "}
                      {paymentLinkInfo?.formatted_amount}
                    </span>
                  </div>
                  <div className="tax_included">{bussinessInfo?.tax}</div>

                  <RenderRow
                    value={paymentLinkInfo?.purpose_payment}
                    title={" Purpose of payment"}
                  />
                  <RenderRow
                    value={paymentLinkInfo?.payment_message}
                    title={"Message"}
                  />
                  <RenderRow
                    value={paymentLinkInfo?.outlet_name}
                    title={"Outlet"}
                  />
                </div>
              </div>
              {Object.keys(customerData).length === 0 && (
                <div className="bussinessCardInfo orderInfoWrapper">
                  <div
                    className="title_acc_cell"
                    style={{
                      fontSize: 12,
                      fontWeight: "500",
                      marginBottom: 9,
                      marginTop: 16,
                    }}
                  >
                    {t("enter_mobile_number_txt")}
                  </div>

                  <div className="" style={{ direction: "ltr" }}>
                    <ReactPhoneInput
                      inputClass={showError ? "border-error " : ""}
                      countryCodeEditable={false}
                      country={countryCode?.toLowerCase()}
                      enableLongNumbers={true}
                      enableSearch={true}
                      preferredCountries={["iq", "ae"]}
                      value={phoneNumber}
                      onChange={(phone, data: CountryData) => {
                        onChangePhoneHandler(phone, data);
                        // setPhoneNumber(phone);
                        // setCountryCode(data.dialCode);
                        // setShowError(false);
                      }}
                    />
                  </div>

                  {errorMessage !== "" && !loading && (
                    <div
                      style={{ marginLeft: 20 }}
                      className={
                        errorMessage !== "" ? "show-error-message" : ""
                      }
                    >
                      <text>{errorMessage}</text>
                    </div>
                  )}
                </div>
              )}
              <br />
              <br />
              <br />
              <br />
              {Object.keys(customerData).length === 0 && (
                <Button
                  loading={otpLoading}
                  titleText={t("send_code")}
                  onPressHandler={() => {
                    sendOtpFunction();
                  }}
                />
              )}
              {Object.keys(customerData).length > 0 && (
                <Button
                  loading={buttonLoading}
                  titleText={`Pay  ${bussinessInfo?.currency.en} ${paymentLinkInfo?.formatted_amount}`}
                  onPressHandler={() => {
                    navigateToPayment();
                    // sendOtpFunction();
                  }}
                />
              )}
              {showCreateModal && (
                <CreateCustomer
                  token={userToken}
                  phoneNumber={customerData?.mobile_number}
                  onPressClose={() => {
                    closeCreateNamePop();
                    // onPressClose();
                  }}
                />
              )}
              {showValidateOtp && bussinessInfo && (
                <ValidateOtp
                  otpDataPrev={otpData}
                  onPressClose={closeValidateModal}
                  bussinessId={bussinessInfo.business_id}
                  bussinessName={bussinessInfo.name}
                  language={"en"}
                  countryCode={countryCode}
                  token={userToken}
                />
              )}
            </>
          ) : (
            <div className="error_no_link">
              <div
                className="bussinessCardInfo"
                style={{ padding: "20px", borderRadius: "4px" }}
              >
                <span style={{ color: "red" }}>{errorMessageBussines}</span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader
          width={50}
          height={50}
          colorProp={"black"}
          wrapperheight={"100vh"}
        />
      )}
    </>
  );
};

export default HomePage;
