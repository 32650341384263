import React, { useState } from 'react'
import { httpGet, httpPost } from '../../axios/axiosUtils';
import { IPAYMENTLINK, IRESTURANT } from '../../pages/Home/utils';

const  useTokenByBussiness = () => {
    const [loading, setLoading] = useState(true);
    const [notFound,setNotFound] = useState<boolean>(false);
    const [errorMessageBussines,setErrorMessage] = useState<string>('');
    const [paymentLinkInfo,setPaymentLinkInfo] = useState<IPAYMENTLINK>();
    const [bussinessInfo,setBussinessInfo] = useState<IRESTURANT>();
    const [userToken, setUserToken] = useState('');
    const getUserToken = async (outletName: string,paymentLink:string) => {
        try {
          setLoading(true);
          const res = await httpGet(`rjs/resto/by/name/${outletName}`);
          if (res.type !== "error") {
            setUserToken(res.data.access_token)
            getPaymnetLinkInfo(res.data.access_token,paymentLink);
          
          } 
        } catch (error: any) {
          
          setNotFound(true);
         
          setLoading(false);
        }
      };
      const getPaymnetLinkInfo = async (token:string,paymnetLink:string) =>{
        try {
           
            const res = await httpPost(`rjs/payment/link/${paymnetLink}`,{},{},token);
           
            if (res.type === "success") {
                setLoading(false);
                setPaymentLinkInfo(res.data.payment_link);
                setBussinessInfo(res.data.restuarant)
            } 
            if (res.type === "error") {
              setLoading(false);
              // console.log(res.data.errdesc)
              // errdesc
              setNotFound(true);
              setErrorMessage(res.message.message);
          } 
            
          } catch (error: any) {
            console.log(error)
            setErrorMessage('Something went wrong');
            setNotFound(true);
            setLoading(false);
          }
      }

      return {
        errorMessageBussines,
        getUserToken,
        getPaymnetLinkInfo,
        paymentLinkInfo,
        bussinessInfo,
        notFound,
        loading,
        userToken,
      }
}

export default useTokenByBussiness;