
import { useEffect, useState } from "react";
import { httpPost } from "../../axios/axiosUtils";
import { URL_CONSTANTS, apiVersion } from "../urlLinks";
import { OtpResData, OtpRespone } from "../../pages/Home/utils";
const useSendOtp = () => {
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpData, setOtpData] = useState<OtpRespone>();
  const [errorMessage ,setErrorMessage] = useState('');
  const [customerData, setCustomerData] = useState<OtpResData>();
  const sendOtpHandler = async (mobile_number: string, country: string,bussiness_id:string,bussiness_name:string,language:string,token:string) => {
    try {
      setLoading(true);
      setOtpLoading(true);
      const res = await httpPost(
        URL_CONSTANTS.get('URL_Send_Otp')!,
        {
          id: bussiness_id,
          mobile_number,
          name: bussiness_name,
          lang: language,
          country,
        },
        {},
        token,
      );
      if (res.type === "success") {
        setOtpData(res);
        localStorage.setItem('otpDataTemp',JSON.stringify(res))
      }
      setLoading(false);
      setOtpLoading(false);
    } catch (error: any) {
      setErrorMessage(error.response.data.message)
      setLoading(false);
      setOtpLoading(false);
    }
  };
  const validOtpHandler = async (
    req_id: string,
    mobile_number: string,
    otp: string,
    token:string,
  ) => {
    setLoading(true);

    try {
      const res = await httpPost(
        URL_CONSTANTS.get('URL_Validate_Otp')!,
        {
          req_id,
          otp,
          mobile_number,
          apiVersion,
        },
        {},
        token
      );
      console.log(res);
      if (res.type === "success") {
        setCustomerData(res);
      }
      else{
        setErrorMessage(res.message)
      }

      setLoading(false);
    } catch (error: any) {
      setErrorMessage(error.message)
      // console.log(error.message);
      // alert(error.message);
      setLoading(false);
    }
  };
  const getCustomerInfo = async (mobile_number: string, name: string,token:string) => {
    var exsitingUser: boolean = false;
    var cutomerData: any = {};
    setLoading(true);
    try {
      // console.log(await sendHttpReq(shopInfo.accessToekn,strUtil.format(URL_CONSTANTS.get('URL_Get_Receipents'),{mobile_number:mobile_number,name:name}) ))
      // console.log(await sendHttpReq(shopInfo.accessToekn,URL_CONSTANTS.get('URL_Get_TrackOrder1')!))
      const res = await httpPost(
        `rjs/create/customer?`,
        {
          mobile_number,
          name,
        },
        {},
        token,
      );
      cutomerData = res.data;
      if (res.data.new_user === true) {
        exsitingUser = true;
      } else {
        exsitingUser = false;
      }

      setLoading(false);
    } catch (error: any) {
      console.log(error.message);
      // alert(error.message);
      setLoading(false);
    }
    return { exsitingUser, cutomerData };
  };
  return {
    sendOtpHandler,
    validOtpHandler,
    loading,
    otpData,
    otpLoading,
    getCustomerInfo,
    errorMessage,
    customerData,
  };
};
export default useSendOtp;
