const URL_BASE_RJS = 'rjs/';
export const apiVersion = 1;
const URL_CONSTANTS = new Map<string, string>([
    ["URL_PLACE_ORDER", URL_BASE_RJS +"confirmed/order/payment/link?"],
    ["URL_Get_TOKEN", URL_BASE_RJS + `resto/by/name/`],
    ["URL_Get_PAYMNETLINKINFO", URL_BASE_RJS + `payment/link/`],
    ["URL_Send_Otp", URL_BASE_RJS + `send/otp?`],
    ["URL_Validate_Otp", URL_BASE_RJS + `validate/otp?`],

   ]);
   export  {URL_CONSTANTS};