import React, { useCallback, useEffect, useState } from "react";
import VerificationInput from "react-verification-input";
import useSendOtp from "../../../hooks/useSendOtp";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import WhatsappIcon from "../SVGS/WhatsappIcon";
import CreateCustomer from "../CreateCustomer/CreateCustomer";
import { OtpRespone } from "../../Home/utils";
import CrossSvg from "../SVGS/CrossSVG";
interface Props {
  token: string;
  bussinessId: number;
  bussinessName: string;
  language: string;
  countryCode: string;
  otpDataPrev: OtpRespone | undefined;
  onPressClose: () => void;
}
const ValidateOtp: React.FC<Props> = (props: Props) => {
  const {
    token,
    bussinessId,
    otpDataPrev,
    bussinessName,
    language,
    countryCode,
    onPressClose,
  } = props;
  const { t } = useTranslation();
  var dataFromStrg = localStorage.getItem("otpDataTemp");
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const otpRes = dataFromStrg !== null && JSON.parse(dataFromStrg);
  const [timer, setTimer] = useState(60);
  const [otpMsidn, setOtpMsIdn] = useState<string>(
    otpRes ? otpRes.data.msisdn : ""
  );
  const {
    validOtpHandler,
    loading,
    customerData,
    sendOtpHandler,
    otpData,
    errorMessage,
  } = useSendOtp();
  const InputType = "tel";
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  const resetTimer = async () => {
    await sendOtpHandler(
      otpMsidn,
      countryCode,
      bussinessId.toString(),
      bussinessName,
      language,
      token
    );
  };
  useEffect(() => {
    if (customerData !== undefined && customerData.type === "success") {
      showSelectDateHandler();
    }
    if (customerData) {
    }
  }, [customerData]);
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);
  useEffect(() => {
    if (otpData) {
      if (!timer) {
        setTimer(60);
      }
      setOtpMsIdn(otpData.data.msisdn);
    }
  }, [otpData]);
  const ShowErrorMessage = (errorMessage: string) => (
    <text>{errorMessage}</text>
  );
  const showSelectDateHandler = async () => {
    var customerInfo = {
      ...customerData?.data,
    };
    switch (customerData?.data?.isNew) {
      case "0":
        localStorage.removeItem("otpDataTemp");
        localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
        onPressClose();
        // checkOrderCount(customerInfo, parsedData);
        // navigate(
        //   `/${bussinessName}/place-order`,
        //   { replace: true }
        //   //  { replace: true }
        // );
        break;
      case "1":
        localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
        setShowCreateModal(true);
        // navigate(`/${bussinessName}/create-customer`, { replace: true });
        break;

      default:
        //for dev
        /** 
          localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
          navigate(`/${bussinessName}/create-customer`, { replace: true });
          */
        break;
    }
  };
  const validateOtpFunction = (value4: string) => {
    let otpCode = value4;

    // uncommnet in production
    validOtpHandler(otpRes.data.req_id, otpMsidn, otpCode, token);

    // for dev propus
    /** 
        localStorage.setItem("customerInfo", JSON.stringify(customerTemp));
        if (customerTemp.Address.length === 0 && customerTemp.isNew === "0") {
          // setShowAlert(true);
          navigate(`/${bussinessName}/place-order`, { replace: true });
        }
        */
  };
  return (
    <div className="pop_up_maps_main pop_main_menu_bg">
      <div className="popup_from_bottom">
        <span className="button-close-style" onClick={onPressClose}>
          {" "}
          <CrossSvg height={25} width={25} wrapperBgColor="black" />
        </span>

        <div
          style={{
            color: "#000000",
            fontSize: 22,
            marginBottom: 9,
          }}
          className=""
        >
          {t("verify_number")}
        </div>

        {/* <span className="icon_sm">
            <WhatsappIcon />
          </span> */}
        <span
          className="light_grey_text res_font_size1"
          style={{ opacity: "65%", fontSize: 12 }}
        >
          {t("send_verification_message")} {"  "}
        </span>
        {otpRes && (
          <div
            style={{ opacity: "65%", fontSize: 12 }}
            className="light_grey_text res_font_size1"
          >
            {otpRes.data.msisdn}
          </div>
        )}
        <VerificationInput
          validChars={"[0-9]{0,5}"}
          length={4}
          inputProps={{ type: InputType }}
          autoFocus
          onComplete={(value: string) => {
            validateOtpFunction(value);
          }}
          classNames={{
            container: "input-otp-container",
            character: "input-otp-character",
            characterSelected: "input-otp-active",
          }}
        />
        {errorMessage !== "" && !loading && (
          <div
            style={{ marginLeft: 20 }}
            className={errorMessage !== "" ? "show-error-message" : ""}
          >
            {ShowErrorMessage(errorMessage)}
            {/* <text>Pleae enter value</text> */}
          </div>
        )}
        {loading && (
          <Loader
            height={10}
            width={10}
            colorProp={"black"}
            wrapperheight="1vh"
          />
        )}
        <div className="cart_content_row cart_content_to_marg">
          {timer > 0 ? (
            <div className="cart_content_alert">
              {t("verifitcation_code_error")} | {timer}
            </div>
          ) : (
            <button
              className="cart_content_alert"
              onClick={resetTimer}
              disabled={loading}
              style={{ width: "100%" }}
            >
              {t("get_new_code")}
            </button>
          )}
        </div>
        {showCreateModal && (
          <CreateCustomer
            token={token}
            phoneNumber={otpMsidn}
            onPressClose={() => {
              onPressClose();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ValidateOtp;
