//import { AxiosRequestConfig } from "axios";
import { AxiosRequestConfig } from "axios";
import { instance } from "./axios";
// TODO Should use the token in the userData context
export const httpGet = async (
  requestPath: string,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.get(requestPath, {
    ...config,
  });
  return axiosResponse.data;
};
export const httpGetWithAuth = async (
  requestPath: string,
  config?: AxiosRequestConfig,
  userToken?: string
): Promise<any> => {
  const axiosResponse = await instance.get(requestPath, {
    headers: {
      Authorization: "Bearer " + userToken,
    },
    ...config,
  });
  return axiosResponse.data;
};
export const httpPost = async (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig,
  userToken?: string
): Promise<any> => {
  const axiosResponse = await instance.post(requestPath, data, {
    headers: {
      Authorization: "Bearer " + userToken,
    },
    ...config,
  });
  return axiosResponse.data;
};

export const httpPut = async (
  requestPath: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.put(requestPath, data, {
    headers: {
      Authorization: "Bearer null",
    },
    ...config,
  });
  return axiosResponse.data;
};

export const httpDelete = async (
  requestPath: string,
  config?: AxiosRequestConfig
): Promise<any> => {
  const axiosResponse = await instance.delete(requestPath, {
    headers: {
      Authorization: "Bearer null",
    },
    ...config,
  });
  return axiosResponse.data;
};
export async function sendHttpReq(finalToken:string,apiURL : string) {
  var resJsonFinal = [];
  try {
    var tokenstr = finalToken;
    const headers = {  'Content-Type': 'application/json'  ,'Authorization': 'Bearer ' + tokenstr  };
  if(tokenstr !=null )
  {
    var tokenR = await fetch(apiURL, { headers });
    const resJson = await tokenR.json();
    //console.log("---->"+JSON.stringify(resJson));
    resJsonFinal = JSON.parse(JSON.stringify(resJson));
  }
  else{
    resJsonFinal=[];
  }
  }
  catch (err) {
    resJsonFinal=[];
  }
 return resJsonFinal;
}
export async function sendHttpPost(finalToken:string,apiURL : string,JsonBody:any) {
  var resJsonFinal = [];
  try {
    var tokenstr = finalToken;
    const headers = {  'Content-Type': 'application/json'  ,'Authorization': 'Bearer ' + tokenstr  };
  if(tokenstr !=null )
  {
    var tokenR = await fetch(apiURL, {  method: 'POST',headers,body:JsonBody});
    const resJson = await tokenR.json();
    //console.log("---->"+JSON.stringify(resJson));
    resJsonFinal = JSON.parse(JSON.stringify(resJson));
  }
  else{
    resJsonFinal=[];
  }
  }
  catch (err) {
    resJsonFinal=[];
  }
 return resJsonFinal;
}