import React, { useState } from 'react'
import { IPLACEORDER } from '../../pages/Home/utils';
import { httpPost } from '../../axios/axiosUtils';
import { toast } from "react-toastify";

const usePlaceOrder = () => {
    const [buttonLoading,setButtonLoading] = useState<boolean>(false);
  const placeOrderEndpoint = async (orderValues:IPLACEORDER,token:string) =>{
    setButtonLoading(true);
    try {
        const res = await httpPost(
            `rjs/confirmed/order/payment/link?`,
            {
              ...orderValues,
              // credit card check
            },
            {},
            token,
          );
          setButtonLoading(false);
          if (res.type ==='success'){
            let redirectionLink = res.data.payment_link;
            return  redirectionLink;
          }
          if (res.type === 'error'){
            toast.error(res.data.errdesc,{position:"top-left"});
          }

        
    } catch (error) {
        setButtonLoading(false);
        toast.error('something went wrong please try again');
    }
  }
  return {
    placeOrderEndpoint,
    buttonLoading,
  }
}

export default usePlaceOrder;