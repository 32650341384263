import axios from "axios";
//new apis 
// new url to use: 
// restoByNameForToken :`resto/by/name${'outletName'}`,
// getBussinessData:'resto',
// getCategories:'categories',
// getItemDetail :'`recipe?id=${itemId}`',

// placeOrder:'confirmed/order?',

// No change: //////////////////
// createCustomer:'create/customer?',
// loadReceipent:'customer/recipients?',
// loadAddress:'get/customer/address?',
// saveAddress:'save/customer/address?',
// saveGreetingMessage :'save/greeting/message?',
// deleteAddress:'delete/customer/main/address?',
// deleteReceiver:'`delete/recipient/${receiver_id}`',

// sendOtp :'send/otp?',
// validOtp:'validate/otp?',

////
// TODO Should be fetched form the env file
//https://adminv1.meemorder.io/api/resto/by/name/cima
// /dmenu/adminv1.meemorder.io/api/resto

//dev urrl https://dev.taiftec.com/dmenu/adminv1.meemorder.io/api/"
// baseURL = "https://adminv1.meemorder.io/api/";
// url for rjs adminv1.meemorder.io/api/rjs/

// const baseURL = "https://adminv1.meemorder.io/api/";
const baseURL = "https://orderapi.dukany.io/api/";

export const instance = axios.create({
  baseURL,
  timeout: 100000,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default instance;
