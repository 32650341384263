import React, { useState } from "react";
import useSendOtp from "../../../hooks/useSendOtp";
import { useTranslation } from "react-i18next";
// import "./homeStyles.css";
import "../../Home/homeStyles.css";
import Button from "../Button";
import { ICUSTOMERINFO, getCustomerData } from "../../Home/utils";
// import SimpleButton from "../SimpleButton/SimpleButton";
interface Props {
  token: string;
  phoneNumber: any;
  onPressClose: () => void;
}
const CreateCustomer: React.FC<Props> = (props: Props) => {
  const { getCustomerInfo, loading } = useSendOtp();
  const { token, onPressClose, phoneNumber } = props;
  const [userName, setUserName] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  var getData = getCustomerData();
  const [customerData, setCustomerData] = useState<ICUSTOMERINFO>(
    getData !== null ? getData : ({} as ICUSTOMERINFO)
  );
  const { t } = useTranslation();
  const createNewCustomerHandler = () => {
    if (userName.length !== 0) {
      getCustomerInfo(phoneNumber, userName, token).then((value) => {
        if (!value.exsitingUser) {
          var tempCustomerData = {
            ...customerData,
            name: userName,
          };
          localStorage.setItem(
            "customerInfo",
            JSON.stringify(tempCustomerData)
          );
          onPressClose();
        }
      });
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="pop_up_maps_main pop_main_menu_bg">
      <div className="popup_from_bottom">
        <div className="cart_content_row">
          <h3 className="">{t("create_new_acount")}</h3>
          <p className="light_grey_text">{t("create_new_acount_txt")}</p>
        </div>

        <div
          className="cart_content_row"
          style={{ marginLeft: "-28px", marginTop: "14px", width: "80%" }}
        >
          <div className="title_acc_cell">{t("text_field_place_holder")}</div>
          <div>
            <input
              onChange={(e) => {
                setUserName(e.target.value);
                setShowError(false);
              }}
              type="text"
              className={`cart_mobile_input_type ${
                showError ? "border-error" : ""
              }`}
              name="fname"
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <Button
            titleText={t("create_button")}
            loading={loading}
            onPressHandler={createNewCustomerHandler}
          />
          {/* <SimpleButton
            titleProp={t("create_button")}
            loading={loading}
            t={t}
            onPressHandler={createNewCustomerHandler}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default CreateCustomer;
